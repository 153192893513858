<template>
    <div
        :class="['transaction-indicator', { 'transition-out': transitionOut }]"
        v-show="(loading && !error) || (didLoad && showComponent && !error)"
    >
        <!--   LOADED CHECKMARK ICON SVG    -->
        <svg
            :class="['loaded', { show: !loading }]"
            width="52"
            height="52"
            viewBox="0 0 52 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16 28.1353L20.0833 34.1896C20.3976 34.68 20.9193 34.9816 21.4839 34.9992C22.0486 35.0168 22.5866 34.7483 22.9283 34.2784L36 17"
                stroke="black"
                class="checkmark"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M26 50.3728C39.4619 50.3728 50.375 39.4597 50.375 25.9978C50.375 12.5359 39.4619 1.6228 26 1.6228C12.5381 1.6228 1.625 12.5359 1.625 25.9978C1.625 39.4597 12.5381 50.3728 26 50.3728Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>

        <!--   LOADING SPINNER ICON SVG   -->
        <svg
            :class="['loading circle', { show: loading }]"
            width="52"
            height="52"
            viewBox="0 0 52 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <defs>
                <clipPath id="cut-off">
                    <rect
                        x="0"
                        y="30"
                        width="100"
                        height="100"
                    />
                </clipPath>
                <linearGradient id="gradient">
                    <stop
                        offset="0"
                        stop-color="#eee"
                    />
                    <stop
                        offset="100%"
                        stop-color="#111"
                        stop-opacity="0"
                    />
                </linearGradient>
            </defs>

            <circle
                cx="26"
                cy="26"
                r="25"
                stroke="#333333"
                stroke-width="2"
                fill="none"
                opacity="1"
            />
            <circle
                cx="26"
                cy="26"
                r="25"
                stroke="url(#gradient)"
                stroke-width="2"
                fill="none"
                clip-path="url(#cut-off)"
            />
        </svg>
        <h5 class="text-center">
            {{ loading ? loadingTitle : loadedTitle }}
        </h5>
    </div>
</template>

<script>
    export default {
        name: 'TransactionIndicator',
        data: function () {
            return {
                transitionOut: false,
                showComponent: true,
                didLoad: false,
            }
        },
        props: {
            loadingTitle: {
                type: String,
                default: '\u00A0',
            },
            loadedTitle: {
                type: String,
                default: 'Done!',
            },
            loading: {
                type: Boolean,
                default: false,
            },
            error: {
                type: [String, Boolean],
            },
        },
        watch: {
            loading: function (newValue) {
                if (newValue === false) {
                    this.didLoad = true
                    this.handleTransitionOut()
                } else {
                    // reset these values to their original state if loading bool changes
                    this.showComponent = true
                    this.transitionOut = false
                }
            },
        },
        methods: {
            handleTransitionOut: function () {
                if (this.error) {
                    this.showComponent = false
                    return
                }
                // allow for user to see last transition before component is hidden
                setTimeout(() => {
                    this.transitionOut = true
                }, 1000)

                // we hide the component completely after 3 seconds (.transition-out is 1.5s + 1s above timeout)
                setTimeout(() => {
                    this.showComponent = false
                }, 3000)
            },
        },
    }
</script>

<style lang="scss" scoped>
    .transaction-indicator {
        width: 100%;
        flex-direction: column;
        overflow: hidden;
        height: calc(100vh - 90px);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 1;

        &.transition-out {
            opacity: 0;
            transition: opacity 0.5s;
            transition-delay: 1s;
        }

        svg {
            display: none;
            margin: 0 auto;

            &.show {
                display: block;
            }
        }

        .circle {
            animation: spin 1.5s infinite linear;
        }

        .checkmark {
            stroke: $black;
            fill: none;
            stroke-width: 1.8;
            stroke-linecap: round;
            animation: dash 1s ease-in-out forwards;
        }

        @keyframes dash {
            0% {
                stroke-dasharray: 0, 100;
            }
            50% {
                stroke-dasharray: 100, 0;
            }
        }

        @keyframes spin {
            0% {
                transform: rotate(360deg);
            }
            100% {
                transform: rotate(0deg);
            }
        }
    }
</style>
