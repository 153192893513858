<template>
    <div>
        <page-header
            :title="showRedeemForm ? $t('pages.rewards.altTitle') : $t('pages.rewards.title')"
            :show-back="true"
            @onClose="onClose"
        />
        <transaction-indicator
            :loading="submitting"
            :error="errorText || error"
            :loaded-title="$t('pages.rewards.redeemedTitle')"
        />
        <loading-indicator
            v-show="loading"
            :title="$t('global.loadingMessage.loading')"
        />

        <div v-if="avenAccountStanding !== AvenAccountStanding.current">
            <feature-not-available />
        </div>

        <div
            v-else-if="errorText"
            class="text-muted text-center mb-4 container"
        >
            <span v-html="errorText" />
        </div>
        <div v-else-if="!showRedeemForm">
            <h2 class="text-center fw-bold mb-0">
                {{ toThousands(remainingRewardsPoints || 0) }}
            </h2>

            <div class="text-muted text-center mb-3">
                <span v-html="$t('pages.rewards.availablePoints')" />
            </div>

            <div class="container d-grid">
                <button
                    class="btn btn-block btn-primary"
                    @click="showRedeemForm = true"
                    :disabled="!remainingRewardsPoints || remainingRewardsPoints <= 0"
                >
                    {{ $t('pages.rewards.altTitle') }}
                </button>
            </div>

            <div v-if="rewardsRedeemedSummary.size">
                <section-header>{{ $t('pages.rewards.listTitle') }}</section-header>
                <div class="d-flex flex-column-reverse">
                    <div
                        v-for="[dateRedeemed, dollarValueOfPoints] of rewardsRedeemedSummary"
                        :key="dateRedeemed"
                    >
                        <list-row
                            row-icon="images/pages/rewards/rewards-arrow-left.svg"
                            :value="`${toFormattedUSD(dollarValueOfPoints)}`"
                            :title="convertDateTimeFormat(dateRedeemed)"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div v-if="!isPrimaryCard && !isCoApplicantCard">
                <div class="text-muted text-center mb-3">
                    <span v-html="$t('pages.rewards.ineligibleSecondaryCard')" />
                </div>
            </div>
            <div v-else>
                <form-container
                    @onSubmit="redeemRewards"
                    class="container"
                    id="redeemRewardsForm"
                    ref="redeemRewardsForm"
                >
                    <div class="text-muted mb-1">
                        {{ $t('pages.rewards.inputLabel') }}
                    </div>
                    <form-field-currency
                        v-model="pointsToRedeem"
                        container-classes="mb-1"
                        name="pointsToRedeem"
                        :placeholder="$t('pages.rewards.inputPlaceholder')"
                        input-type="tel"
                        inputmode="decimal"
                        :validation="`points:1,${remainingRewardsPoints}`"
                        :options="{ numeral: true, numeralPositiveOnly: true }"
                        :disabled="submitting"
                        focus
                    />
                    <list-row
                        style="pointer-events: none"
                        row-icon="images/pages/rewards/money.svg"
                        :value="toFormattedUSD(dollarValueOfPoints)"
                        value-class="fw-bold"
                        :title="$t('pages.rewards.cashbackAmountText')"
                        container-classes="bg-light mx-0 mt-2 px-3"
                    />
                    <base-button
                        class="mt-3"
                        :submitting="submitting"
                        button-classes="btn btn-primary"
                    >
                        {{ $t('pages.rewards.redeem.btnCta') }}
                    </base-button>
                </form-container>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import { i18n } from '@/utils/i18n'
    import format from '@/mixins/format'
    import { logger } from '@/utils/logger'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { postRedeemRewards, getRewardsSummary } from '@/services/api'
    import LoadingIndicator from '@/components/LoadingIndicator'
    import PageHeader from '@/components/PageHeader'
    import BaseButton from '@/components/base/BaseButton'
    import FormFieldCurrency from '../components/base/FormFieldCurrency'
    import FormContainer from '@/components/base/FormContainer'
    import ListRow from '@/components/ListRow'
    import SectionHeader from '@/components/SectionHeader'
    import generic from '@/utils/generic'
    import TransactionIndicator from '@/components/TransactionIndicator'
    import FeatureNotAvailable from '@/components/FeatureNotAvailable'
    import { AvenAccountStanding } from '@/store'
    import { navigation } from '@/mixins/navigation'

    export default {
        name: 'Rewards',
        components: {
            TransactionIndicator,
            FormContainer,
            LoadingIndicator,
            PageHeader,
            BaseButton,
            FormFieldCurrency,
            ListRow,
            SectionHeader,
            FeatureNotAvailable,
        },
        mixins: [format, navigation],
        data: function () {
            return {
                loading: true,
                error: false,
                errorText: '',
                submitting: false,
                showRedeemForm: false,
                pointsToRedeem: null,
                rewardsRedeemedSummary: '',
                AvenAccountStanding,
            }
        },
        mounted: async function () {
            this.showRedeemForm = false
            if (!this.isLoyaltyProduct) {
                logger.info(`This account is ineligible for rewards`)
                return await this.$router.replace('card')
            }
            await this.rewardsSummary()
            this.loading = false
        },
        computed: {
            ...mapGetters(['remainingRewardsPoints', 'isLoyaltyProduct', 'isPrimaryCard', 'isCoApplicantCard', 'dollarToCashbackRewardPointsConversion', 'avenAccountStanding']),
            dollarValueOfPoints: function () {
                return (this.pointsToRedeem * this.dollarToCashbackRewardPointsConversion).toFixed(2)
            },
        },
        methods: {
            rewardsSummary: async function () {
                try {
                    const response = await getRewardsSummary()
                    if (!response.data.success) {
                        logger.info('Redeem Rewards failed with ', response.data.error)
                        this.$refs.redeemRewardsForm.applyGeneralError(response.data.error)
                        return false
                    }
                    logger.info(`GetRewardsSummary: ${JSON.stringify(response.data)}`)
                    const rewardsRedeemedList = response.data.payload.rewardsRedeemedResponse
                    if (!rewardsRedeemedList?.length) {
                        logger.info(`There are no rewards for this customer yet`)
                        return true
                    }

                    let rewardsRedeemedSummary = new Map()
                    for (const redeemedReward of rewardsRedeemedList) {
                        rewardsRedeemedSummary.set(redeemedReward.dateRedeemed, redeemedReward.dollarValueOfPoints)
                    }

                    this.rewardsRedeemedSummary = rewardsRedeemedSummary
                    this.pointsToRedeem = this.remainingRewardsPoints.toString()

                    return true
                } catch (e) {
                    ApiErrorHandler(e)
                    this.errorText = i18n.t('pages.rewards.genericError')
                    return false
                }
            },
            redeemRewards: async function () {
                const isValid = this.$refs.redeemRewardsForm.validateAll()
                if (!isValid) {
                    logger.info('redeeemRewardsForm is invalid')
                    this.submitting = false
                    return
                }

                this.error = false
                this.submitting = true

                try {
                    if (this.pointsToRedeem > this.remainingRewardsPoints) {
                        logger.info(`Trying to redeem more points that those are available. Remaining reward points to be redeemed: ${this.remainingRewardsPoints}`)
                        this.$refs.redeemRewardsForm.applyGeneralError(i18n.t('pages.rewards.redeemMoreThanRemainingError', { maxPointsAvailableForRedemption: this.remainingRewardsPoints }))
                        return false
                    }

                    const response = await postRedeemRewards(this.pointsToRedeem, this.dollarValueOfPoints)
                    if (!response.data.success) {
                        this.error = true
                        logger.info(`Redeem Rewards failed with ${response.data.error}`)
                        this.$refs.redeemRewardsForm.applyGeneralError(response.data.error)
                        return false
                    }
                    logger.info(`Rewards Redemption successful. ${JSON.stringify(response.data)}`)
                    await this.$store.dispatch('updateAccountOverview')
                    await this.rewardsSummary()
                    this.showRedeemForm = false
                    return true
                } catch (e) {
                    ApiErrorHandler(e)
                    this.$refs.redeemRewardsForm.applyGeneralError(i18n.t('pages.rewards.genericError'))
                    return false
                } finally {
                    this.submitting = false
                    this.pointsToRedeem = null
                }
            },
            convertDateTimeFormat: function (date, options) {
                return generic.convertDateTimeFormat(date, options)
            },
            onClose: function () {
                // do not try to html inline this
                // it will not work in webview
                if (this.showRedeemForm) {
                    this.showRedeemForm = false
                } else {
                    this.returnToRoot()
                }
            },
        },
    }
</script>

<style lang="scss" scoped></style>
